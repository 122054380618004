import React, { useEffect } from "react"
import { useLocation } from "@reach/router"
import "../assets/bootstrap.min.css"
import "../styles/style.scss"
import HeaderTeam from "./headerteam"
import Footer from "./footer"
import "../styles/teams.scss"
const LayoutTeam = props => {
  const location = useLocation()

  useEffect(() => {
    const fullUrl = `${location.pathname}${location.search}${location.hash}`
    // Store the current URL in localStorage whenever it changes

    if (!fullUrl.includes("login")) {
      // Store the full URL in localStorage if it doesn't contain "login"
      localStorage.setItem("lastVisitedUrl", fullUrl)
    }
  }, [location])

  return (
    <div>
      <HeaderTeam />
      {props.children}
      <Footer />
    </div>
  )
}
export default LayoutTeam
